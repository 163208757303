import { render, staticRenderFns } from "./special_forecast.vue?vue&type=template&id=a65ecad6&scoped=true&"
import script from "./special_forecast.vue?vue&type=script&lang=ts&"
export * from "./special_forecast.vue?vue&type=script&lang=ts&"
import style0 from "./special_forecast.vue?vue&type=style&index=0&id=a65ecad6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a65ecad6",
  null
  
)

export default component.exports